.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 500px;
	&_cart {
		min-height: 650px; 
	}
	@include respond-to('medium') {
		min-height: 400px;
	}
	@include respond-to('small') {
		min-height: inherit;
	}
	&__text {
		position: absolute;
		left: 0;
		right: 0;
		top: 60%;
		transform: translateY(-50%);
		margin: 0 auto;
		padding: 0 15px;
		max-width: 1230px;
		width: 100%;
		z-index: 1;
		@include respond-to('medium') {
			// padding: 15px;
			// position: static;
			// transform: none;
			display: none;
		}
		& span {
			display: block;
			font-size: 50px;
			font-weight: 900;
			color: #fff;
			line-height: 1;
			max-width: 300px;
			width: 100%;
			@include respond-to('large') {
				font-size: 35px;
			}
			@include respond-to('medium') {
				font-size: 30px;
				color: #000;
				font-weight: 400;
			} 
		}
	}
}

.banner-top-content__image {
	background-position: center center;
	background-size: cover;
	height: 500px;
	// box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
	&_cart {
		height: 650px;
	}
	@include respond-to('medium') {
		height: 400px;
	}
}