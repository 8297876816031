.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
}

.hp-cats {
	&__items {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
		align-items: stretch;
	}
	&__item {
		width: 12.5%;
		padding: 30px 0;
		// min-height: 183px;
		border-bottom: 3px solid transparent;
		@include respond-to('medium') {
			width: 25%;
			padding: 20px 0;
		}
		@include respond-to('small') {
			width: 33.33%;
		}
		@include respond-to('extra-small') {
			width: 50%;
		}
		&:hover {
			border-bottom: 3px solid $accent;
		}
	}
}

.hp-cat-item {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	text-align: center;
	@include link_no-hover(#000);
	&__img {
		margin-bottom: 25px;
		& img {
			max-height: 110px;
		}
		@include respond-to('small') {
			margin-bottom: 15px;
		}
	}
	&__title {
		max-width: 110px;
		margin: 0 auto;
		color: #000;
		font-size: 20px;
		font-weight: 600;
		line-height: 1;
		@include respond-to('small') {
			font-size: 18px;
		}
	}
}

.title {
	position: relative;
	margin: 0;
	padding-bottom: 20px;
	font-size: 30px;
	font-weight: 400;
	color: $title;
	line-height: 1;
	@include respond-to ('medium') {
		// font-size: 35px;
	}
	@include respond-to ('small') {
		font-size: 30px;
	}
	@include respond-to ('extra-small') {
		font-size: 25px;
	}
	&_decor {
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			margin: 0 auto;
			width: 175px;
			height: 3px;
			background-color: $accent;
		}
	}
	&_decor-white {
		&::after {
			background-color: $accent;
		}
	}
	&_tac {
		text-align: center;
	}
	&_tal {
		text-align: $left;
	}
	&_mb {
		margin-bottom: 80px;
		@include respond-to('huge') {
			margin-bottom: 50px;
		}
		@include respond-to('medium') {
			margin-bottom: 35px;
		}
		@include respond-to('small') {
			margin-bottom: 20px;
		}
	}
	&_white {
		color: #fff;
	}
}

.products {
	&__title {}
	&__items {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-flow: row wrap;
		@include respond-to('huge') {
			justify-content: space-around;

		}
		&_end {
			// justify-content: flex-end;
			justify-content: space-around;
			@include respond-to('huge') {
				justify-content: space-around;
			}
		}
	}
	&__item {
		min-width: 350px;
		width: 25%;
		position: relative;
		@include respond-to('small') {
			min-width: 290px;
		}
		&:hover {
			& .product-box {
				border: 1px solid #e4e4e4;
				&::after {
					opacity: 1;
				}
			}
			& .circle-black {
				opacity: 0.6;
				&:hover {
					opacity: 1;
				}
			}
		}
	}
}

.sec-padd {
	padding: 80px 0;
	@include respond-to('huge') {
		padding: 70px 0;
	}
	@include respond-to('large') {
		padding: 60px 0;
	}
	@include respond-to('1100') {
		padding: 50px 0;
	}
	@include respond-to('medium') {
		padding: 40px 0;
	}
	@include respond-to('small') {
		padding: 30px 0;
	}
	@include respond-to('extra-small') {
		padding: 25px 0;
	}
}

.custom-arrow2 {
	& .slick-arrow {
		width: 10px;
		height: 24px;
	}
	& .slick-next {
		#{$left}: 0px;
		@include respond-to('larger') {
			#{$left}: 15px;
		}
		@include respond-to('medium') {
			#{$left}: 0px;
		}
		&:before {
			content: url(../images/arr-l2.png);
			opacity: 1;
		}
	}
	& .slick-prev {
		#{$right}: 0px;
		@include respond-to('larger') {
			#{$right}: 15px;
		}
		@include respond-to('medium') {
			#{$right}: 0;
		}
		&:before {
			content: url(../images/arr-r2.png);
			opacity: 1;
		}
	}
}

.hp-about {
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: $title, $alpha: 0.9);
	}
	&__title {
		position: relative;
		z-index: 1;
		margin-bottom: 30px;
	}
	&__text {
		position: relative;
		z-index: 1;
		color: #fff;
	}
}