.cart-page {
	position: relative;
	background-color: #fff;
	.loader-bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.8);
		z-index: 100;
		&.hidden {
			display: none;
		}
		.loader {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto auto;
			width: 40px;
			height: 40px;
		}
	}
}
.cart-lower-btns {
	margin: 20px 0px;
	@include respond-to('medium') {
		button,
		input {
			margin: 0px auto;
			float: none;
			display: block;
		}
	}
}

.cart-items-table {
	margin: 0px 0px;
}

.cart-cell-white {
	background-color: #fff;
}

.cart-image img {
	max-width: 75px;
}

.cart-image-hide {
	@include respond-to('medium') {
		display: none !important;
	}
}


.cart-units {
	width: 88px;
	height: 31px;
	margin: 0 auto;
	border-radius: 3px;
	overflow: hidden;
	border: 1px solid rgba(0, 189, 255, 1);
	@include respond-to('small') {
		width: 30px;
	}
	.cart--units-button {
		border: 0;
		font-size: 12px;
		float: right;
		width: 29px;
		height: 100%;
		background-color: rgba(0, 189, 255, 1);
		color: #fff;
		@include respond-to('small') {
			display: none;
		}

		&:disabled {
			cursor: not-allowed;
		}
	}
	.cart-units--add {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		border-left: 1px solid rgba(0, 189, 255, 1);
	}
	.cart-units--remove {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		border-right: 1px solid rgba(0, 189, 255, 1);
	}
	.cart-units--input {
		border: 0;
		float: right;
		width: 28px;
		height: 100%;
		font-size: 16px;
		text-align: center;
	}


}

.remove-cart-item {
	cursor: pointer;
	width: 19px;
	height: 32px;
	margin: 0 auto;
	display: block;
	svg {
		width: 19px;
	}
	&:hover {
		svg {
			fill: red;
		}
	}
}
.remove-cart-item-input {
	visibility: hidden;
}

.cart-panel {
	width: 100%;
	height: 47px;
	background-color: rgba(0, 189, 255, 1);
	color: #fff;
	font-size: 24px;
	padding-top: 5px;
	padding-right: 10px;
	border: 1px solid #ddd;
	margin: 2px 0px;
}

.cart-section {}

.cart-svg-icon {
	padding-top: 10px;
}

.cart-td {
	vertical-align: middle !important;
}

.nmargin {
	margin: 0;
}

.cart-fgroup {
	label {
		width: 100px;
	}
	select {
		min-width: 200px;
	}
	input {
		min-width: 200px;
	}
	.text {
		width: 200px;
		display: inline-block;
	}
}

.cart-refreash {
	margin-right: 10px;
	cursor: pointer;
	display: inline-block;
	color: green;
	i {
		margin-bottom: -3px;
		display: block;
		float: right;
	}
}

.prod-price-buy {
	background-color: $accent;
	border: 1px solid $accent;
}  