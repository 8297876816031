.modal-custom {
	.modal-content {
		border-radius: 0;
	}
	.btn-r {
		border-radius: 0;
		border: 1px solid $accent;
		color: $accent;
		&:hover {
			background-color: #e8eef0;
		}
	}
	.btn-l {
		border-radius: 0;
		margin-#{$right}: -4px !important;
		background-color: $accent;
		border: 1px solid $accent;
		&:hover {
			background-color: #0fa8dd;
		}
	}
}