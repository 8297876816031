.sb-slidebar {
	z-index: 99;
	background-color: #222222;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	background-color: $accent;
	border-radius: 3px;
}

.mobile-menu-btn {
	float: $right;
	margin: side-values(18px 15px 0 10px);
}

.sb-left .sb-menu,
.sb-right .sb-menu {
	& li {
		border-bottom: 0;
		&.hasSub {
			position: relative;
			& > i {
				position: absolute;
				top: 10px;
				#{$left}: 15px;
				border: 1px solid #fff;
				padding: 5px;
				z-index: 9;
			}
			&.hasSub-open {
				background-color: hsla(0, 0%, 100%, .05);
				& > ul {
					display: block;
					& > li > a {
						& > i {
							position: absolute;
							top: 20px;
							#{$right}: 15px;
							z-index: 9;
						}
					}
				}
			}
		}
		& a {
			color: #fff;
			border-bottom: 1px solid rgba(0, 0, 0, .1);
			font-size: 15px;
			&:hover {
				background-color: unset;
			}
		}
		& ul {
			display: none;
			li {
				padding-#{$right}: 0;
			}
		}
	}
	& ul {
		padding: 0;
		li > a {
			padding-#{$right}: 35px;
			position: relative;
			& > i {
				position: absolute;
				top: 20px;
				#{$right}: 15px;
				z-index: 9;
			}
		}
	}
}

.header-search {
	display: inline-block;
	// float: $left;
	height: 100%;
	width: 100%;
	// vertical-align: top;

	@include respond-to('large') {
		// margin-#{$left}: 250px;
	}
	@include respond-to('1100') {
		// margin-#{$left}: 220px;
	}
	&_mobile {
		margin-#{$left}: 0;
		float: none;
		width: 100%;
		border-left: 1px solid $accent;
		border-bottom: 1px solid $accent;
		& .search {
			width: 100%;
			& input {
				color: #949494;
				&::placeholder {
					color: #949494;
				}
			}
			& i {
				font-size: 20px;
			}
		}
	}
}

.search {
	position: relative;
	width: 100%;
	&__icon {
		position: absolute;
		#{$right}: 10px;
		top: 12px;
		color: #fff;
	}
	& input {
		width: 100%;
		border: none;
		padding: side-values(0px 15px 0px 40px);
		height: 40px;
		font-size: 18px;
		font-weight: 400;
		text-indent: 5px;
		background-color: #fff;
		font-style: italic;
		color: #000;
		@include respond-to('large') {
			font-size: 16px;
		}
		&::placeholder {
			font-size: 18px;
			font-weight: 400;
			color: #8f8f8f;
			@include respond-to('large') {
				font-size: 16px;
			}
		}
	}
	& button {
		position: absolute;
		top: 0;
		height: 40px;
		#{$left}: 0px;
		width: 40px;
		border: none;
		background: $accent;
		text-align: center;
		padding: 6px;
		font-size: 18px; 
		font-weight: 700;
		color: #fff;
		&:active {
			@include btn-effect-active();
		}
	}
}
