.wrapper-banners {
	position: relative;
	width: 100%;
	// height: 700px;
	height: 500px;
	overflow: hidden;
	@include respond-to('huge') {
		// height: 650px;
	}
	@include respond-to('large') {
		height: 450px;
	}
	@include respond-to('1100') {
		height: 400px;
	}
	@include respond-to('medium') {
		height: 350px;
	}
	@include respond-to('extra-small') {
		height: 300px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	// min-height: 700px;
	min-height: 500px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('huge') {
		// min-height: 650px;
	}
	@include respond-to('large') {
		min-height: 450px;
	}
	@include respond-to('1100') {
		min-height: 400px;
	}
	@include respond-to('medium') {
		min-height: 350px;
	}
	@include respond-to('extra-small') {
		min-height: 300px;
	}
}

.banner-info {
	position: absolute;
	left: 50%;
	top: 60%;
	transform: translate(-50%, -50%);
	max-width: 540px;
	width: 100%;
	text-align: center;
	// @include respond-to('large') {
	// 	transform: translate(-50%, -50%);
	// }
	&__title {
		margin: 0;
		line-height: 0.9;
		font-size: 60px;
		font-weight: 300;
		color: #fff;
		@include respond-to('huge') {
			font-size: 50px;
		}
		@include respond-to('small') {
			font-size: 40px;
			font-weight: 400;
		}
		@include respond-to('extra-small') {
			font-size: 30px;
		}
		& span {
			display: block;
			font-weight: 900;
			color: $accent;
		}
	}
	&__text {
		margin-top: 20px;
		font-size: 35px;
		font-weight: 300;
		color: #fff;
		line-height: 0.9;
		// @include respond-to('huge') {
		// 	font-size: 40px;
		// }
		@include respond-to('large') {
			font-size: 30px;
			font-weight: 400;
		}
		@include respond-to('extra-small') {
			font-size: 25px;
		}
	}
	&__btn {
		margin-top: 10px;
	}
}

.main-btn {
	display: inline-block;
	width: 160px;
	height: 50px;
	padding: 9px;
	text-align: center;
	border: 2px solid $accent;
	font-weight: 700;
	font-size: 20px;
	@include link_no-hover($accent);
	&:active {
		@include btn-effect-active();
	}
}

.custom-dots {
	& .slick-dots {
		& li {
			margin: 0 3px;
			width: 10px;
			height: 10px;
			& button {
				width: 10px;
				height: 10px;
				padding: 0;
				&::before {
					content: '';
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: transparent;
					border: 1px solid #fff;
					opacity: 1;
				}
			}
			&.slick-active {
				& button {
					&::before {
						background-color: #fff;
					}
				}
			}
		}
	}
}

.custom-arrow {
	& .slick-arrow {
		width: 50px;
		height: 50px;
		top: 55%;
		@include respond-to('small') {
			display: none;
		}
	}
	& .slick-next {
		#{$left}: 55px;
		@include respond-to('larger') {
			// #{$left}: 15px;
		}
		@include respond-to('small') {
			#{$left}: 15px;
		}
		&:before {
			content: url(../images/arr-l.png);
			opacity: 1;
		}
	}
	& .slick-prev {
		#{$right}: 55px;
		@include respond-to('larger') {
			// #{$right}: 15px;
		}
		@include respond-to('small') {
			#{$right}: 15px;
		}
		&:before {
			content: url(../images/arr-r.png);
			opacity: 1;
		}
	}
}