.product__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid #cccccc;
	height: 467px;
}

.product__item.related {
	padding-top: 30px;
}

.product__image {
	height: 215px;
	width: 250px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.product__title {
	font-size: 25px;
	margin-bottom: 5px;
	text-align: center;
}

.product__subtitle h3 {
	font-size: 18px;
	color: #8d8d8d;
	margin-top: 0;
	margin-bottom: 0;
}

.product__title a {
	color: #000000;
	font-size: 20px;
}

.product__title a:hover {
	color: $accent;
	text-decoration: none;
}

.price__wrapper {
	margin: 15px 0 15px 0;
}

.price__amount .price__amount-symbol {
	font-size: 16px;
	color: #000000;
}

.price__amount bdi {
	font-size: 20px;
	color: #000000;
}

.button__wrapper {
	display: flex;
	margin-bottom: 45px;
}

.for_buying {
	width: 120px;
	height: 41px;
	background-color: #00bdff;
	color: #ffffff;
	font-size: 18px;
	&:active {
		@include btn-effect-active();
	}
}

.for_details {
	display: block;
	width: 120px;
	height: 41px;
	line-height: 41px;
	border: 1px solid #00bdff;
	color: #00bdff;
	font-size: 18px;
	text-align: center;
	@include link_no-hover($accent);
	&:active {
		@include btn-effect-active();
	}
	&_item {
		// padding: ;
		background-color: transparent;
		// line-height: inherit;
	}
}

.product__lable {
	background-image: url(../images/orange_bage.png);
	width: 134px;
	height: 44px;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	top: -11px;
	left: 0;
	text-align: center;
	line-height: 35px;
	z-index: 999;
}

.product__lable.operation {
	background-image: url(../images/green_bage.png);
}

.product__lable-content {
	color: #ffffff;
	font-size: 18px;
}

.sale__lable {
	position: absolute;
	background-image: url(../images/sale__lable.png);
	background-repeat: no-repeat;
	background-size: contain;
	width: 290px;
	height: 290px;
	top: 30%;
	left: 15%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sale__lable-content {
	text-align: center;
	color: #ffffff;
	font-size: 23px;
	transform: rotate(-20deg);
}

.sale__lable-content-amount {
	font-weight: 700;
	font-size: 35px;
	margin-bottom: -15px;
}
