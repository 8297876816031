.category__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid #cccccc;
	@include link_no-hover(#000);
	&:hover {
		color: $accent;
		text-decoration: none;
	}
}

.category__image {
	height: 215px;
	width: 250px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.category__title {
	font-size: 25px;
	margin-bottom: 25px;
	text-align: center;
}
