.header-desktop {
	// height: 100px;
	@include respond-to('medium') {
		display: none;
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 5px;
		float: $left;
		& > img {
			max-width: 85px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

// .mob_menu__button {
// 	width: 24px;
// 	background: transparent;
// 	border: none;
// 	padding: 0;
// 	margin: 0;
// 	display: none;
// }

// .mob_menu__button span {
// 	width: 100%;
// 	float: left;
// 	display: block;
// 	height: 2px;
// 	border-radius: 1px;
// 	background-color: #000;
// 	position: relative;
// 	transition: .2s;
// }

// .mob_menu__button span:not(:last-child) {
// 	margin-bottom: 5px;
// }

header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 9999;
	transition: .3s;
	background-color: #ffffff;
}
header.active {
	box-shadow: -2px 5px 50px rgba(0, 0, 0, 0.15);
}
header .top_area {
	width: 100%;
	padding: 5px 0;
	background-color: #00bdff;
}
header .top_area .inside_cont {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
header .top_area .inside_cont ul {
	width: 100%;
	max-width: 225px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	margin: 0;
}
header .top_area .inside_cont ul li {
	list-style: none;
}
header .top_area .inside_cont ul a {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: #ffffff;
	font-size: 18px;
	font-weight: 400;
}
header .top_area .inside_cont ul a span {
	margin-right: 15px;
}
header .top_area .inside_cont .basket {
	position: relative;
	margin-right: 93px;
}
header .top_area .inside_cont .basket i {
	position: absolute;
	color: #000000;
	font-size: 16px;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	background-color: #ffffff;
	border-radius: 50%;
	top: -6px;
	right: -14px;
	font-style: normal;
}
header .top_area .inside_cont form {
	width: 100%;
	max-width: 250px;
	margin-right: 29px;
	position: relative;
}
header .top_area .inside_cont form input {
	width: 100%;
	border: 1px solid #cccccc;
	background-color: #ffffff;
	color: #5b5b5b;
	font-size: 18px;
	font-weight: 400;
	height: 30px;
	padding-left: 50px;
	padding-right: 16px;
}
header .top_area .inside_cont form button {
	position: absolute;
	background-color: transparent;
	padding: 0;
	margin: 0;
	border: none;
	left: 15px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
}
header .bottom_area {
	width: 100%;
	padding: 4px 0;
}
header .bottom_area .inside_cont {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
header .bottom_area .inside_cont nav {
	width: 100%;
	max-width: 1020px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
header .bottom_area .inside_cont nav a {
	color: #000000;
	font-size: 18px;
	font-weight: 400;
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 815px) {
	header .bottom_area .inside_cont nav {
		display: none;
	}
	.mob_menu__button {
		display: block;
	}
}


/// menu css

@charset "UTF-8";
a {
	color: #00bdff;
}

.flex_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

.logo-wrap img {
	width: 100px;
}

.sf-menu {
	position: static;
	float: none;
}
.sf-menu .sf-mega {
	background-color: #ffffff;
	width: 830px;
	right: inherit;
	left: inherit;
	top: 105%;
}
.sf-menu .sf-mega ul {
	display: block;
	position: relative;
	top: 8%;
	box-shadow: none;
	text-align: right;
	padding: 0 35px 0 35px;
}
.sf-menu .sf-mega ul li {
	position: relative;
	border-bottom: #dddddd 1px solid;
}
.sf-menu .sf-mega ul li:last-child {
	border-bottom: none;
}
.sf-menu .sf-mega ul li a {
	font-size: 18px;
	padding: 15px 0;
}

.sf-menu .sf-mega ul li::after {
	content: "";
	background-image: url(../images/menu_rows.png);
	position: absolute;
	background-repeat: no-repeat;
	width: 20px;
	height: 20px;
	top: 45%;
	right: -12%;
}

.sf-menu li {
	white-space: normal;
	position: static;
}
.sf-menu > li {
	float: right;
}
.sf-menu > li > ul {
	width: 220px;
	left: auto;
}

.responsive-img {
	width: 100%;
}

.toggle-mnu {
	display: block;
	width: 28px;
	height: 28px;
	margin-top: 14px;
}
.toggle-mnu span:after,
.toggle-mnu span:before {
	content: "";
	position: absolute;
	left: 0;
	top: 9px;
}
.toggle-mnu span:after {
	top: 18px;
}
.toggle-mnu span {
	position: relative;
	display: block;
}
.toggle-mnu span,
.toggle-mnu span:after,
.toggle-mnu span:before {
	width: 100%;
	height: 2px;
	background-color: #666666;
	transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 2px;
}
.toggle-mnu.on span {
	background-color: transparent;
}
.toggle-mnu.on span:before {
	transform: rotate(45deg) translate(-1px, 0px);
}
.toggle-mnu.on span:after {
	transform: rotate(-45deg) translate(6px, -7px);
}

.toggle-mnu {
	float: right;
	margin-top: 30px;
	position: relative;
	z-index: 2;
}

.extra__menu {
	padding: 0;
	margin: 0;
	list-style: none;
	margin-top: 20px;
}
.extra__menu li {
	padding: 10px 10px;
	border-bottom: 1px solid #dddddd;
}
.extra__menu li:last-child {
	border-bottom: none;
}

.mm-panels > .mm-panel {
	padding-top: 0;
}

.sf-menu a {
	color: #333333;
	background-color: #fff;
}

.sf-menu ul li {
	background: none;
}
.sf-menu ul li:hover {
	background: none;
}

.sf-menu > li {
	background-color: #fff;
}
.sf-menu > li > a {
	padding: 30px 15px;
	transition: all 0.5s ease;
	color: #333333;
	font-size: 18px;
	@include respond-to('1100') {
		padding: 30px 10px;
	}
}
.sf-menu > li > a + * a {
	transition: all 0.5s ease;
}
.sf-menu > li > a + * a:hover {
	color: #00bdff;
}
.sf-menu > li:hover > a {
	background-color: #fff;
	color: #00bdff;
}

.mega__menu-img-wrapper {
	border-right: #dddddd 1px solid;
}


/* Medium Devices, Desktops */
@media (max-width: 992px) {

	header .bottom_area {
		padding: 20px 15px 20px 15px;
	}

	.toggle-mnu {
		display: block;
	}

	.top-mnu ul {
		display: none;
	}
}

@media (min-width: 992px) {
	.toggle-mnu {
		display: none;
	}

	.toggle-mnu,
	#mobile-menu,
	html.mm-bloking #mm-blocker {
		display: none;
	}

	html.mm-opening .mm-slideout {
		transform: translate(0px 0px);
	}
}
