// ###########################################################
.wrapper-clockdiv{
  position: relative;
  // left: 100px;
  // bottom: 20px;
  z-index: 1;
  @include respond-to('medium'){
    // left: 15px;
  }
}

.wrapper-clockdiv-title{
  font-size: 34px;
  font-weight: bold;
  color: #000;
}

.title-clockdiv{
  margin-bottom: 15px;
  font-size: 22px;
  color: #fff;
  @include respond-to('medium'){
    font-size: 18px;
    p *{
      font-size: 18px !important;
    }
  }
}

#clockdiv{
	font-family: sans-serif;
	color: #fff;
	display: inline-block;
	font-weight: 100;
	text-align: center;
  font-size: 25px;
  @include respond-to('medium'){
    font-size: 16px;
  }
  
}

#clockdiv > div{
	padding: 10px;
	border-radius: 3px;
	background: #981b1e;
  display: inline-block;
  @include respond-to('medium'){
    padding: 7px;
  }
}

#clockdiv div > span{
	padding: 10px;
	border-radius: 3px;
	background: #000;
	display: inline-block;
}

.smalltext{
	padding-top: 5px;
	font-size: 14px;
}

.section-text-sale{
  margin: 25px 0 15px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  border: 1px solid #981b1e;
  padding: 15px;
  box-shadow: 10px 10px 25px 0px rgba(0,0,0,0.1);
  &__hr{
    margin: 13px 0 10px;
    border-top: 1px solid #ddd;
  }
  & .spansale{
    margin-bottom: 15px;
  }
}

.sale-txt-link{
  color: #981b1e;
  text-decoration: underline;
  &:focus,&:hover{
    color: #981b1e;
  }
}
// ##################
