.custom-form {
	.ftr-check-uncheck {
		& label {
			border-radius: 0;
			border-color: #8b8a8a;
			&::after {
				border-color: #8b8a8a;
			}
		}
	}
	.fileupload {
		position: relative;
		.note {
			position: absolute;
			top: 4px;
			#{$right}: -180px;
			color: #f9ed00;
			text-align: $left;
			@include respond-to('small') {
				position: relative;
				#{$right}: 0;
			}
		}
	}
	.btn-file {
		box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, .1);
		background-color: #fff;
		color: #8b8a8a;
		font-size: 18px;
		padding: 0 15px;
		border: 1px solid #d6d6d6;
		border-radius: 3px;
		width: 100%;
		height: 40px;
		text-align: $right;
		padding-#{$right}: 40px;
		display: block;
		padding-top: 8px;
		cursor: pointer;
		overflow: hidden;
		position: relative;

		&:after{
      font-family: "Font Awesome 5 Pro"; 
      content: "\f093";
      position: absolute;
      top: 10px;
      #{$right}: 15px;
      color: #000;
      
    }
    & .fa-upload{
      float: #{$left};
    }
    &.uploading{
      &:before{
        font-family: "Font Awesome 5 Pro"; 
        content: "\f110";
        animation: spiner 2s infinite linear;
        float: #{$right};
        margin-#{$right}: 10px;
        color: #000;
      }
    }
    @keyframes spiner {
        0%  {-webkit-transform: rotate(0deg);}
        100% {-webkit-transform: rotate(360deg);}
    }
	}

	.btn-file input[type=file] {
		position: absolute;
		top: 0;
		height: 38px;
		#{$right}: 0;
		width: 100%;
		font-size: 100px;
		text-align: $right;
		filter: alpha(opacity=0);
		opacity: 0;
		outline: none;
		background: white;
		cursor: inherit;
		display: block;
	}

	h2 {
		font-size: 24px;
		color: #000;
		font-weight: bold;
		margin-bottom: 0;
		margin-top: 0;
	}

	span.small-note {
		color: #000;
		font-size: 16px;
	}

	p.note {
		color: #000;
		font-size: 17px;
		margin-bottom: 30px;
		font-weight: lighter;
	}

	div.form-head-spacer {
		width: 100%;
		height: 25px;
	}

	hr.form-hr {
		margin: 0px;
		margin-top: 13px;
		margin-bottom: 30px;
		border: 0;
		height: 1px;
		width: 100%;
		background-color: #d2d2d2;
	}

	.form-control {
		box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, .1);
		background-color: #fff;
		color: #000;
		font-size: 20px;
		padding: 5px 15px;
		border: 1px solid #d6d6d6;
		border-radius: 0px;
		width: 100%;
		height: 40px;
		&:focus {
			outline: none;
			box-shadow: none;
			&::placeholder {
				opacity: 0;
				transition: opacity 0.3s ease;
			}
		}
		&::placeholder {
			color: #000;
			font-size: 18px;
			opacity: 1;
			transition: opacity 0.3s ease;
		}

	}

	.form-group {
		margin-bottom: 17px;
	}

	.has-error {
		.form-control {
			border: 1px solid #a94442;
			&::placeholder {
				color: #a94442;
				font-size: 18px;
			}
		}
	}

	textarea.form-control {
		min-height: 100px;
		resize: none;
	}

	$activeColor: #f9ed00; //green
	$darkenColor: darken($activeColor, 20%);
	$background: #f9ed00;


	/* .customCheckbox */
	section {
		padding-top: 10px;
	}
	.customCheckbox {
		width: 24px;
		height: 24px;
		position: relative;
		float: $right;
		margin-bottom: 17px;
		label {
			width: 24px;
			height: 24px;
			cursor: pointer;
			position: absolute;
			top: 0;
			#{$left}: 0;
			background: #fff;
			transition: all 0.3s;
			&:after {
				content: '';
				width: 14px;
				height: 7px;
				position: absolute;
				top: 5px;
				#{$left}: 4px;
				border: 2px solid #fff;
				border-top: none;
				border-#{$right}: none;
				background: transparent;
				opacity: 0;
				transform: rotate(-45deg);
			}
			&:hover {
				background-color: #000;
			}
			&:hover::after {
				opacity: 0.8;
			}
			&:focus {
				outline: 0;
			}
		}

		&.force-checked label {
			background-color: #000;
		}
		&.force-checked label::after {
			opacity: 1;
		}

		input[type=checkbox] {
			visibility: hidden;
			margin: 0;
			width: 24px;
			height: 24px;
			&:checked + label {
				background-color: #000;
			}

			&:checked + label:after {
				opacity: 1;
			}
		}
	}

	.customCheckboxText {
		float: $right;
		font-size: 15px;
		color: #000;
		margin-#{$right}: 9px;
		max-width: 85%;
	}

	.btn-form {
		margin-top: 22px;
		display: block;
		width: 100%;
		height: 45px;
		font-size: 20px;
		font-weight: 700;
		color: #fff;
		border: 0;
		background-color: $accent;
		position: relative;
		border-radius: 0px;
	}
	.select-wrapper {
		position: relative; 
		select {
			position: relative;
			text-indent: 1px;
			text-overflow: '';
		}
		&:after {
			position: absolute;
			top: 9px;
			#{$left}: 9px;
			width: 27px;
			height: 27px;
			content: " ";
			pointer-events: none;
		}
	}

	.date-box {
		position: relative;
		cursor: pointer;

		&:after {
			position: absolute;
			top: 9px;
			#{$left}: 9px;
			width: 27px;
			height: 27px;
			content: " ";
			pointer-events: none;
		}
	}

}

.form-bg-holder {
	padding: 0px 20px;
}

.form-circle-note {
	width: 240px;
	height: 240px;
	position: absolute;
	text-align: center;
	#{$left}: 0;
	#{$right}: 0;
	margin: auto auto;
	top: 170px;
	display: inline-block;
	border-radius: 50%;
	background-color: #f9ed00;
	border: 4px solid #fff;
	box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.2);
	padding: 43px 0px;
	color: #fff;
	@include respond-to('small') {
		margin: 0 auto;
		position: relative;
		float: none;
		top: 0;
		#{$left}: 0;
		#{$right}: 0;
		bottom: 0;
		display: block;
		margin-bottom: 20px;
	}
	.headline {
		font-size: 24px;
		margin-bottom: 10px;
	}
	.text {
		font-size: 16px;
		a {
			color: #000;
			&:focus,
			&:hover {
				color: #000;
			}
		}
	}

}

.custom-form {
	@include respond-to('medium') {
		border: 0;
	}
	iframe {
		width: 420px !important;
		height: 350px !important;
		max-width: 100%;
		margin: 25px;
		background-color: #fff;
		border: 1px solid #e2e3e5;
		overflow: hidden;
		padding: 5px;
		border-radius: 5px;
		box-shadow: inset 0px 0px 3px 0px rgba(226, 227, 229, 1);
	}
	.a11y-checkbox,
	.a11y-radio {
		position: absolute;
		border: 0;
		margin: -1px;
		padding: 0;
		width: 1px;
		height: 1px;
		overflow: hidden;
		clip: rect(0px, 0px, 0px, 0px);
	}

	/*
  Gather the magic.
  <label> must be relative (psuedo elements will be position absolute)
  & inline-block or block (e.g. not inline. so padding is applied consistantly across various browsers)
  */
	.a11y-checkbox-label,
	.a11y-radio-label {
		position: relative;
		display: inline-block;
		margin: side-values(0 0 5px 0);
		padding: side-values(3px 30px 0 0);
	}

	/*
  Cast the magic.
  Create the box where our custom images will live.
  */
	input[type="checkbox"] + .a11y-checkbox-label:before,
	input[type="radio"] + .a11y-radio-label:before {
		position: absolute;
		top: 0rem;
		#{$right}: 0;
		width: 26px;
		height: 26px;
		line-height: 26px;
		overflow: hidden;
		margin: 0;
		padding: 0;
	}

	/*
  Invoke the magic.
  Load the custom images as content rather than background images so they still render in high-contrast mode.
  */

	/* Checkbox - Default State */
	input[type="checkbox"] + .a11y-checkbox-label:before {
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAANElEQVR42mP4TyfAMGoR1SwqLCykCibKIkrBqEWjFo1aNGrRqEWjFlHDIrpUfKONk0FnEQAcTRTfX8LFVAAAAABJRU5ErkJggg==);
	}

	/* Radio Button - Default State */
	input[type="radio"] + .a11y-radio-label:before {
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAABQ0lEQVR42u1WSwqDMBDtoQVd6E7xs3LjEVzoKRT1DH5wr95A8TPlZRVoNWlKC4UOBOL83mQyM/FGX6LbH+ijQF3XUZqm5DgOaZrGFvbgQfY20DRNFEUReZ5HWZbROI60ritb2IMHGXSgqwTU9z1ZlkVVVdG+76cOICuKgkzTZDYvASE6gLRtK30H0IXN2ckegI7joDAMWZSvEmxgCx9CIETm+/5luq7SCNtnmXgASpKE8jxXLmPYwocQyLZtVlGqhDuCDyEQegTlq0rbtjEfQiDDMJiyKs3zLAfkuq6w+a5oGAa51MVx/HYxwIcQqK5rCoJAubxh2zTNZxu2LEv5huVHkOxk5kfQWWtIDdVnEfIZUB6q/MmQCjwFuGR8o/SXZWHVBR5k0FF+JviIcbkYKyhb9Iiu66wNwIPs6sT/n5PfAboDjREsqed+e4MAAAAASUVORK5CYII=);
	}

	/*
  Mmmooorrree power!
  Swap the image based on state.
  */

	/* Checkbox - Selected State */
	input[type="checkbox"]:checked + .a11y-checkbox-label::before {
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAA9UlEQVR42mP4TyfAMGoR1SySL9lPFTxq0eCxyG/i2f9Xn37+79h5knYW2bWf+P/2yy+wvitPPtPGIsP6I//vvf4G1vPh2+//Tl0U+ki75jCGmEblof9nH3wEq//5++//kKnnKIuj6JkXwK7NXnwVLqZUduD/jsuvwWr//fv/P3PRFcoTw6l7H8Biv//8+x83+yJYbMGRJ3C1TZtuUyfVgeLhxvMvYPHvv/7+X37iGVzd3EOPqZu8LZqP/X/45huKmm2XXoGDkOr5yL7jxP+XH3+C5U/f//BfvfIg7TKse++p/+cffvxvUHdktKwbKRaNNk4GnUUAgVikctopkvMAAAAASUVORK5CYII=);
	}

	/* Radio Button - Selected State */
	input[type="radio"]:checked + .a11y-radio-label::before {
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAABI0lEQVR42mP4TyfAMGoRTS06evvd/8o1N//bd5z4r1J+EIxBbJDYkVvvKLfozsuv/8Onn/8vX7IfLwapAakly6ITd9//16o+RNASGAapBekhySKQ63RqDhNtCQyD9ODyGYZF//79/x8w+SzJlsAwSC/IDIIWHQZGLrmWwPBhLAkEw6KK1TcotghkBkGL7NpP4DQgbvbF/y8//gRjEBuXOpAZBC0C5RFcBoAsgAEQG5c6kBkELcKXpJEtevWJQoscO0/iNCBhziWwBc8+/ACzcamzJSboCpdfozgxgMwgaNHBG28ptujQzbeDKMNSWgTdJrYIomuhiuwzYoIRpIbsagI5zkCRCypWQMlWufzAf82qQ/+duk6CxUBy2OJktM0w9CwCADnp82FkjM15AAAAAElFTkSuQmCC);
	}

	/* Checkbox - Default Focused State */
	input[type="checkbox"]:focus + .a11y-checkbox-label::before {
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAABIUlEQVR42u3Wz0rDQBCA8X0TX9JX8uDNuwePooJ40YtQQYxaKrWttmnzp2N/pQ8QMSwIWRgo6cz3ZWeX7KbYje02omm3Ue+iatpY122UVRurqvlVqFGLgYWJbSQ/PJTwvW5iuqxistjE22wdxWcZL9NuIVeNWgwszPogS81BsijrGM83MZqs4qH4irvnedw8zeJq1C3kqlGLgYWJzZEY2f3xOF7ui87vP+Ls9j1Or1/j5LLoFHLVqMXAwsTmSPppqt5CwtHxRS+BhYnNkUxNX03Z20j668DAwsTmSHaKRdRfU+9LhIWJzZFsSzvGYupzXyIsTGyOvcj21FOL2pcICxN7EA2iQfQfRVk+Qdk+qtmOiWwHX7ajPNvlJNt1K9cF8gfi5rg8W6WU/gAAAABJRU5ErkJggg==);
	}

	/* Radio Button - Default Focused State */
	input[type="radio"]:focus + .a11y-radio-label::before {
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAACxUlEQVR42r2WW2sTURDH96so+qCIlzfBVxUU9Nt4QW0qCr4oYn1W8PZspb5V0LSbpuAFKhZi01jFxDQXm9rdjSbZ3Yznd7IRs91s01U6cJbDmfn/Z86cmXPWkBjpdkV89XH9rnQ8X9quL61gMGcNnacGtnFiRC32ySFrtj2xfrmy5nSkbrWlutEbzFlDhw22YPwhHo3wDrwgesDrzY6s/mjJ5/pP+bjqyIeSJQtfe4M5a+iwwRYM2KgdGmEnRGarKGsq4hVF8r5oydxyQ6YX6/J8oSqT7yp6MGcNHTbYggELR9jZH0d9J6SirCLMlR3JFtY14dVneTk78VYOpTKy+3xaD+asocMGWzBgrb+cDTgir2zZDpwslmx5lVuTuy++yLEb83L85mu5N1OU5WpTEzCYs4YOG2zBgIUDLjj7Z6YdcYjkl0POlW15mfsu16YKcuCKKY+z3wYiCwu6B5mS7LtkagxYOOCC0w2wBg6JkMNcqTV1zolu/2VTzHxDRpXZpYbGgIUDLjjhxofhBbuhcjjUKZXvo9ezOsrtysO5ksbCAReccOPDYGscHmVKJOOTeTmh8h6Xrrg0goUDLjjhxofBgdF49ATlekZV0v3ZoiQVsHDABSfc+DDIIV1OA1KmB8dMXVFJpaCwcMAFJ9z4MLi3qBC6nUakR1AkFaKHAy44KxstfTducrT34ow2TipOy4t2FE7d4fGM3n5SWao4A6mr9VMXLobTt9/8czGcUhybiiFc3qmneTl5K3l5gx2LKu9hDUvzbVcexTVs1BU0EVxBmQRX0J3pIVfQVpfqE3Wp+jHvNLqRLtVhzwQAdkYqeAo45E8qStfrBUV1sYYOG2zBxD4T0Q+frVNAvlPqcLlWKNtd59Ky50Jajqg2YA0dNtiCiX34op5ytk6eR37KlS2YLZ/yHfs52dHfragfSKL7Hz+QvwFay95sEEon3AAAAABJRU5ErkJggg==);
	}

	/* Checkbox - Selected Focused State */
	input[type="checkbox"]:checked:focus + .a11y-checkbox-label::before {
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAB/0lEQVR42r2Wz0sbQRTH9z8p9NKCl3rrqUUv3jx5E3ovvRZBEAU9qAUPPXiQIpQWKb2JhyJ48FfQGNHa1ogg2MYmURuricb82N3k634GFYtWs7rugwfhZd7nO/Pm7cxY8qxaldxKVY7ntltRyamoaFdUsF1fTg65MGDBhI1Z/CDIgHzJ1f6xrcxhWdvZklIHRSX3a3PGkkMuDFgwnVMxyz0VOSw62s2VtZkpaC11pJWtnBZ/ZrWwWZszlhxyYcCCCRsNC0XU+WNj99gkTcT3NLq8o8+xtEbmUzU5Y8khFwYsmLDRsKgnS2UWDHjwejIQhwUTNhoWS6OuLJnZBCUECyZsNCw6hU2kviw9KCFYMGGjYdGWdAybSZ2DEoIFEzYaRoj2pKZsql9g89slxb0SNfQv/BOHBRP2nYWe9Ub1N2+bD3I1eXQ/QvVdEdNVWLbgqPHNHVdU1zF7Kfa4fUZLv3JGpOx1VMvg8qUxvoRah1bMbF99jJ/HHrZNafxHRmdn5MsPq1eu2JdQzIthjlvVi3ffTOx9JKkz6x7b+G9pfQmxD+vbeQPlW/gUTZ+LDM/8vnYPfe/R0545JfYKumhfvv8xJQxUCH/eFzUHJMZH+Kh9+sauvHV7Nw3E9DWR05POSE3tf6VQKEdQaIdqaNdEaBdfaFd5aI+T0J5bYT0gTwDdRUgilpwj2QAAAABJRU5ErkJggg==);
	}

	/* Radio Button - Selected Focused State */
	input[type="radio"]:checked:focus + .a11y-radio-label::before {
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAACdklEQVR42r2W3UobQRTH502K0nqp9q7QJ1DQRxAforbemIiFXpbaW30GFb1TqClae6FC0gopKlpaiZr4UXcTzcduTue37ohddzdJIwmcMMz8P2bOnJlZJTG/el3E1X81ty5Vx5VKzZWyH7TpY8zRATbup8I6jThipYoj1k1NzotVKVgVOb26Ddr0MQYGLBw3wlEFV+D4s4d8WarK8Z+yHBau5cdxUb4fWZL+dRu06WMMDFg4cMNWqIImzMzWs8zrGR9okcxvS77sXcjyTkGW0qcyv33iBW36GAMDFg5cNIJmd0bGhFTk9AyzuaJs7F96gpMLuzI8vSV9iXXpfp3ygjZ9jIEBCweudc/sHyPyypJt32TnyJbV7Ll8XPkpL999lSevVmMDDFg4cNFAC02zZ54Rm0h+2eRszpZP2TOZWtyXZ+OfG5qYAAsHLhpooVnzV6UwZJls5kG+5OWc2fW0YGICDlw00EITbTyU46+GymFTF3W+X7zdaNnEBFw00EITbTwUS2PzKFNmkpzf/W8TE2ighSbaeCg2jIPHmaBch3QltWuEBlpooo2HIoeccg4gZdo7sRYpMDKTubsZaEfh0EALTbTxUNxbEDntHETOSJQAOPOjHYVDAy00T67K3t34wCiupO8bcQu0ZBRMXX9yPVJgdPabZ8CBpN1M6vImdcFiGHy/2XYxDGiNB8UQLO/EXPvlPRFW3h07sGFX0HQbV9CH5YgrqGOXatQzAYGVNZNGMGDhxD4T4Q+f7aWAfCf05nKtULZdYyl5+iYlz/UxoI8xMGDhxD58YU85SyfPTT/lGgun4VPesY+Tjn5uhX1AMrvH+ID8C1mcpWxuv0aqAAAAAElFTkSuQmCC);
	}

	/* Checkbox - Disabled State */
	input[type="checkbox"]:disabled + .a11y-checkbox-label {
		color: #666;
	}
	input[type="checkbox"]:disabled + .a11y-checkbox-label::before {
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAkUlEQVR42u3WMQrAIAwFUO9/E0UEQXB0chHvlBJB6NIaYywUDHySIfDIFgUflTqQGGSMAa116ysZQohYayGlBLVWVkgQLuWcwTkHMca9EC6XUsB7z8KmoBVsGuJiLIiDsaFZbAmawZYhKiYCUTAxaISJQm+YOPSEbYHuWAihzdugjuFVCG6FehAjQxI5z8n/oAtIFaAVSCl5xgAAAABJRU5ErkJggg==);
	}

	/* Radio Button - Disabled State */
	input[type="radio"]:disabled + .a11y-radio-label {
		color: #666;
	}
	input[type="radio"]:disabled + .a11y-radio-label::before {
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAABSElEQVR42u2WWYqEMBCG+/5HEVdERXF5EBWXE3gBlze9QA1/IEOQdCex6YGBbigQU399lViV6gf90e/xBX0UtG0bTdNEcRyTbdvM8Ix3WHsbdJ4nVVVFvu9TURTUdR2N48gMz3iHNfjA9xZo33fyPI/KsmSZvzL4wBcaIxCycxyHwjBUQrjVdc1292xnUhCOJM9zCoKAkiTRhmFn0GqB1nVlmUE4DIMxDFrEUILgjKy40BTGtUoQyhYVJYpNYNAihhKEHkH5XgPowqBFDCXIdV0pSISJRyvz0QIh4+vRidY0DQuEJpWtt21LURSpQX3fv8xYBYMWMZSgZVl+y/sOTLu8ecPqXD1XmFHD8isImeFa0YWlaco0x3F87lLNsowsy6J5nu+PCT4KrmMC1SWuqeaS1uDDx0XmKFscE3qNN67sw3//M/xP0A85wNINWnbF9wAAAABJRU5ErkJggg==);
	}
}

.c-f-paddng {
	padding: 0px 50px;
	border-#{$right}: 1px solid #dfe0e0;
	border-#{$left}: 1px solid #dfe0e0;
	margin: 0 auto;
	hr {
		background-color: #dfe0e0 !important;
		border-color: #dfe0e0 !important;
	}
	@include respond-to('medium') {
		border: 0;
	}
}