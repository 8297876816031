button:active,
button:focus {
	outline: none;
}

button::-moz-focus-inner {
	border: 0;
}

a:active,
a:focus {
	outline: none;
}

input,
textarea {
	outline: none;
}

input:active,
textarea:active {
	outline: none;
}

:focus {
	outline: none;
}

textarea {
	resize: none;
}

textarea {
	resize: vertical;
}

textarea {
	resize: horizontal;
}

input {
	margin: 0;
}

* {
	box-sizing: border-box;
}

button {
	cursor: pointer;
	border: none;
}

a {
	text-decoration: none;
	transition: .3s;
}

/** Main Styles **/

body {
	font-family: Assistant, sans-serif;
	font-weight: normal;
}

.padding-15 {
	padding-left: 15px;
	padding-right: 15px;
}

.container {
	width: 100%;
	max-width: 1230px;
}

body.active {
	overflow: hidden;
}

.wrapper {
	overflow: hidden;
}

.responsive_wrapper {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	z-index: 100;
	transition: .3s;
	transform: translateX(110%);
	background: #26768c;
	overflow-y: auto;
}

.responsive_wrapper .inner {
	width: 100%;
	position: relative;
	padding: 30px 30px;
	padding-bottom: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.responsive_wrapper .inner .close_menu {
	position: absolute;
	top: 10px;
	right: 20px;
	color: #fff;
}

.responsive_wrapper .inner .close_menu svg {
	width: 15px;
}

.responsive_wrapper .inner nav {
	width: 100%;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.responsive_wrapper .inner nav a {
	color: #fff;
	font-size: 22px;
	font-weight: 400;
	margin-bottom: 20px;
}

.responsive_wrapper .inner nav a:last-child {
	margin-bottom: 0;
}

.responsive_wrapper .inner form {
	width: 100%;
	max-width: 250px;
	position: relative;
	margin: 20px 0;
}

.responsive_wrapper .inner form input {
	width: 100%;
	border: 1px solid #cccccc;
	background-color: #ffffff;
	color: #5b5b5b;
	font-size: 18px;
	font-weight: 400;
	height: 30px;
	padding-left: 50px;
	padding-right: 16px;
}

.responsive_wrapper .inner form button {
	position: absolute;
	background-color: transparent;
	padding: 0;
	margin: 0;
	border: none;
	left: 15px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
}

.responsive_wrapper .inner ul {
	width: 100%;
	max-width: 225px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	margin: 0;
}

.responsive_wrapper .inner ul li {
	list-style: none;
}

.responsive_wrapper .inner ul a {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.responsive_wrapper .inner .basket {
	position: relative;
	margin-right: 93px;
}

.responsive_wrapper .inner .basket i {
	position: absolute;
	color: #000000;
	font-size: 16px;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	background-color: #ffffff;
	border-radius: 50%;
	top: -6px;
	right: -14px;
	font-style: normal;
}

.responsive_wrapper.active {
	transform: none;
}

.banner__section {
	height: 485px;
	background-repeat: no-repeat;
	background-position: bottom;
	position: relative;
	display: flex;
	align-items: center;
	padding-top: 130px;

}

.banner__section h1 {
	color: #ffffff;
	font-weight: 900;
	font-size: 50px;
	display: block;
	max-width: 250px;
}


.page__title h1 {
	font-size: 30px;
}

.page__title.shop__page {
	margin-top: 40px;
	margin-bottom: 20px;
}

.page__title {
	margin-bottom: 35px;
}

.none-padding {
	padding: 0;
}

.footer {
	margin-top: 100px;
	padding-top: 100px;
}


.footer__menu h2 {
	margin-bottom: 35px;
	font-size: 18px;
	font-weight: 700;
	position: relative;
}

.footer__menu h2::before {
	content: "";
	position: absolute;
	width: 170px;
	height: 1px;
	background-color: #23c3fb;
	bottom: -18px;
}

.footer__menu ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.footer__menu ul li {
	margin-bottom: 10px;
	font-size: 16px;
}

.footer__menu ul li a {
	font-size: 16px;
	color: #939393;
}

.adress__section li a {
	color: #000000 !important;
}

.copiright {
	position: relative;
	text-align: center;
	margin-top: 115px;
	padding-top: 40px;
}

.copiright::before {
	content: "";
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	height: 1px;
	background-color: #23c3fb;
}

.copiright a {
	color: #000000;
	font-weight: 700;
}



/*** Shop Page ***/

.breadcrumb_wrapper {
	position: relative;
	margin-bottom: 45px;
}


.breadcrumb_wrapper ol {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	flex-flow: row wrap;
}

.breadcrumb_wrapper ol li {
	padding-left: 5px;
	font-size: 18px;
	color: #929292;
}

.breadcrumb_wrapper ol li a {
	padding-left: 5px;
	font-size: 18px;
	color: #00bdff;
}

.breadcrumb_wrapper ol li::before {
	content: "/";
	padding-left: 5px;

}

.breadcrumb_wrapper ol li:first-child:before {
	content: '';
}

.breadcrumb_wrapper::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 1.5px;
	left: 0;
	right: 0;
	// top: 35px;
	bottom: -10px;
	background-color: #dedede;
}

/*** Single Page ***/

.product_wrap {
	width: 100%;
	padding: 70px 0;
}

.product_wrap .inside_cont {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.product_wrap .inside_cont .left_side {
	width: 100%;
	max-width: 600px;
	position: relative;
}

.product_wrap .inside_cont .left_side .top_block {
	width: 100%;
	border: 1px solid #cccccc;
}

.product_wrap .inside_cont .left_side .top_block .slide {
	position: relative;
}

.product_wrap .inside_cont .left_side .top_block .slide .main_photo {
	width: 100%;
}

.product_wrap .inside_cont .left_side .top_block .slide .scale {
	position: absolute;
	bottom: 15px;
	right: 15px;
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product_wrap .inside_cont .left_side .bottom_block {
	// width: calc(100% + 14px);
	margin-left: -7px;
	margin-right: -7px;
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	margin-top: 15px;
}

.product_wrap .inside_cont .left_side .bottom_block .slick-list,
.product_wrap .inside_cont .left_side .bottom_block .slick-track {
	// width: 100% !important;
}


.product_wrap .inside_cont .left_side .bottom_block .slide {
	// margin: 0 7px;
	// width: calc(33.333% - 14px) !important;
	padding: 0 7px;
}

.product_wrap .inside_cont .left_side .bottom_block .slide figure {
	// width: 100%;
	border: 1px solid #cccccc;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product_wrap .inside_cont .left_side .bottom_block .slide figure img {
	width: 100%;
}

.product_wrap .inside_cont .right_side {
	width: calc(100% - 600px);
	padding-right: 60px;
}

.product_wrap .inside_cont .right_side .breadcrumb {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 15px;
	border-bottom: 1px solid #dedede;
	padding-right: 0;
	margin: 0;
	@include respond-to('large') {
		flex-flow: row wrap;
	}
}



.product_wrap .inside_cont .right_side .breadcrumb li {
	list-style: none;
	position: relative;
	color: #929292;
	font-size: 18px;
	font-weight: 300;
	margin-left: 30px;
	@include respond-to('large') {
		margin-left: 20px;
	}
}

.product_wrap .inside_cont .right_side .breadcrumb .active {
	color: #00bdff;
}

.product_wrap .inside_cont .right_side .breadcrumb li:last-child:before {
	display: none;
}

.product_wrap .inside_cont .right_side .breadcrumb li:before {
	content: "/";
	color: #929292;
	font-size: 18px;
	font-weight: 300;
	left: -15px;
	position: absolute;
	top: 0px;
}

.product_wrap .inside_cont .right_side .breadcrumb li a {
	color: #929292;
	font-size: 18px;
	font-weight: 300;
}

.product_wrap .inside_cont .right_side .name {
	width: 100%;
	margin-top: 30px;
	color: #045391;
	font-size: 40px;
	font-weight: 400;
	margin-bottom: 0;
}

.product_wrap .inside_cont .right_side .single__product-title {
	color: #00bdff;
	margin-bottom: 10px;
}

.product_wrap .inside_cont .right_side .article {
	width: 100%;
	color: #00bdff;
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 30px;
}

.product_wrap .inside_cont .right_side .text {
	width: 100%;
	color: #a2a2a2;
	font-size: 18px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 0;
	padding-bottom: 35px;
	border-bottom: 1px solid #dedede;
	margin-bottom: 25px;
}

.product_wrap .inside_cont .right_side .text b {
	color: #045391;
}

.product_wrap .inside_cont .right_side .price_cont {
	width: 100%;
}

.product_wrap .inside_cont .right_side .price_cont span {
	width: 100%;
	color: #a2a2a2;
	font-size: 18px;
	font-weight: 400;
	line-height: 25px;
	margin-bottom: 10px;
}

.product_wrap .inside_cont .right_side .price_cont p {
	display: flex;
	align-items: center;
}

.product_wrap .inside_cont .right_side .price_cont p i {
	color: #00bdff;
	font-size: 40px;
	font-weight: 400;
	font-style: normal;
}

.product_wrap .inside_cont .right_side .price_cont p small {
	font-size: 20px;
}

.product_wrap .inside_cont .right_side .price_cont p b {
	color: #000000;
	font-size: 30px;
	font-weight: 400;
	text-decoration: line-through;
	margin-right: 53px;
}

.product_wrap .inside_cont .right_side .buy_link {
	width: 100%;
	margin-top: 39px;
	max-width: 280px;
	height: 60px;
	background-color: #60b4e1;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 25px;
	font-weight: 400;
	margin-bottom: 20px;
}

.product_wrap .inside_cont .right_side .list {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 237px;
	padding: 0;
	margin: 0;
}

.product_wrap .inside_cont .right_side .list li {
	display: flex;
	list-style: none;
	align-items: center;
}

.product_wrap .inside_cont .right_side .list li p {
	color: #000000;
	font-family: Assistant;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0;
	margin-right: 10px;
}


.single__product-description ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
	position: relative;
}

.single__product-description ul::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: -19px;
	width: 100%;
	height: 1px;
	background-color: #dedede
}

.single__product-description ul li {
	margin-bottom: 30px;
	font-size: 18px;
	color: #a2a2a2;
}

.single__product-description ul li span {
	font-size: 18px;
	color: #86188d;
}

.relate__product-title {
	border-top: 1px solid #dedede;
	margin-bottom: 30px;
}

.relate__product-title h2 {
	color: #838383;
	font-size: 25px;
	margin-top: 25px;
}

/*** Mini Cart ***/

.basket {
	cursor: pointer;
}

.mini__cart {
	display: none;
	background-color: #ffffff;
	width: 600px;
	position: relative;
	padding-bottom: 25px;
	position: absolute;
	z-index: 999999;
	top: 120%;
	left: 50%;
}
.mini__cart .mini__cart-content {
	padding-left: 20px;
	padding-right: 20px;
}
.mini__cart .mini__cart-content .mini__cart-header {
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
}

.mini__cart .mini__cart-content .mini__cart-header::before {
	content: "";
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	top: 60px;
	height: 1px;
	background-color: #dddddd;

}

.mini__cart .mini__cart-content .mini__cart-header h3 {
	font-size: 18px;
	color: #000000;
	margin: 0;
}

.mini__cart-control {
	display: flex;
	justify-content: flex-end;
	padding-top: 25px;
}

.mini__cart-control ul {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.mini__cart-control ul li {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 20px;
	padding-right: 20px;
	border: 1px solid #00bdff;
}

.continue-serf {
	background-color: #00bdff;
}

.continue-serf a {
	color: #ffffff;
	background-image: url(../img/micart.png);
	background-repeat: no-repeat;
	background-repeat: no-repeat;
	background-position: 91%;
	padding-right: 50px;
}

.mini__cart-control ul li a {
	font-size: 18px;
}

.extra__menu li {
	position: relative;
}

.extra__menu li::before {
	content: "";
	background-image: url(../img/menu_rows.png);
	position: absolute;
	background-repeat: no-repeat;
	background-position: 128%;

}


#close__mini svg {
	color: #929292;
	cursor: pointer;
}

@media (max-width: 1199px) {

	.mini__cart {
		left: 35%;
	}

	.sale__lable {
		width: 210px;
		height: 210px;
		top: 41%;
	}

	.sale__lable-content-amount {
		font-weight: 700;
		font-size: 25px;
		margin-bottom: -10px;
	}

	.sale__lable-content {
		font-size: 18px;
	}

	.product_wrap {
		padding: 40px 0;
	}

	.banner__section {
		height: 375px;
	}

	.sf-menu > li > a {
		font-size: 14px;
	}


}

@media (max-width: 991px) {
	.mini__cart {
		width: 480px;
	}

	header .top_area .inside_cont ul {
		display: none;
	}

	header .top_area .inside_cont .basket {
		margin-right: 5px;
	}

	header .top_area .inside_cont {
		justify-content: space-between;
	}

	.sale__lable {
		width: 145px;
		height: 145px;
		top: 48%;
		left: 5%;
	}

	.sale__lable-content-amount {
		font-size: 18px;
		margin-bottom: -4px;
	}
	.sale__lable-content {
		font-size: 12px;
	}

	.banner__section {
		height: 290px;
	}

	.product__lable {
		left: 15px;
	}
	.product_wrap .inside_cont .right_side {
		width: 100%;
		padding-right: 0;
		max-width: 560px;
	}
	.product_wrap .inside_cont {
		flex-direction: column;
		align-items: center;
	}
	.product_wrap .inside_cont .left_side {
		max-width: 500px;
		margin-bottom: 30px;
	}
}

@media (max-width: 850px) {



	.mini__cart {
		width: 100%;
		left: 0;
		right: 0;
		top: 100%;
	}

	.footer {
		background-image: none !important;
		background: #b5e4f1;
	}
	.none-padding {
		padding: 0 15px 0 15px;
	}

	.sale__lable-content-amount {
		font-weight: 700;
		font-size: 23px;
		margin-bottom: -10px;
	}
	.sale__lable-content {
		font-size: 15px;
	}
	.relate__product-title {
		padding: 0 15px 0 15px;
	}
}

@media (max-width: 568px) {
	.mini__cart-control ul li a {
		font-size: 16px;
	}

	.mini__cart-control ul li {
		padding-left: 10px;
		padding-right: 10px;
	}

	.banner__section h1 {
		color: #ffffff;
		font-weight: 900;
		font-size: 29px;
		display: block;
		max-width: 175px;
		margin-right: 20px;
	}

	.banner__section {
		height: 280px;
		background-size: cover;
		background-position: center;
	}
}
